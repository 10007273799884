<template>
  <div> 
    <v-text-field v-model="to" label="To"></v-text-field> 
     <v-container fluid>
      <v-textarea
        name="input-7-1"
        filled
        label="Body"
        auto-grow
        v-model="body"
        maxlength = '250'
      ></v-textarea>
    </v-container>
    <v-btn class="mr-4" @click="create">
      submit
    </v-btn>
  </div>
</template>
<script>
import Swal from "sweetalert2";
export default {
  data: () => ({ 
    to: null, 
    body: null,
  }),
  methods: {
    create() {
      Swal.fire({
        title: "",
        text: "The sms has been successfully sent!",
        icon: "success",
        confirmButtonClass: "btn btn-secondary",
      });
    },
  },
};
</script>
