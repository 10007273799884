<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <form class="form" id="kt_form">
        <!--begin: Wizard Step 1-->
        <div class="pb-5">
          <h4 class="mb-10 font-weight-bold text-dark">
            Enter Account Details
          </h4>
          <v-avatar v-if="url != null" size="128">
            <img :src="url" alt="John" />
          </v-avatar>
          <v-file-input
            @change="Preview_image"
            v-model="image"
            :rules="rules"
            accept="image/png, image/jpeg, image/bmp"
            placeholder="Choose Image"
            prepend-icon="mdi-camera"
            label="Profile Image"
          ></v-file-input>
          <!-- firstName -->
          <div class="form-group">
            <input
              type="text"
              class="form-control form-control-solid form-control-lg"
              name="fname"
              v-model="form.firstName"
              placeholder="First Name"
            />
            <span class="form-text text-muted">Please enter First name.</span>
          </div>
          <div class="form-group">
            <input
              type="text"
              class="form-control form-control-solid form-control-lg"
              name="mname"
              v-model="form.middleName"
              placeholder="Middle Name"
            />
            <span class="form-text text-muted">Please enter Middle name.</span>
          </div>
          <!-- lastName -->
          <div class="form-group">
            <input
              type="text"
              class="form-control form-control-solid form-control-lg"
              name="lname"
              placeholder="Last Name"
              v-model="form.lastName"
            />
            <span class="form-text text-muted">Please enter last name.</span>
          </div>
          <div class="form-group">
            <input
              type="text"
              class="form-control form-control-solid form-control-lg"
              name="uname"
              placeholder="User Name"
              v-model="form.userName"
            />
            <span class="form-text text-muted">Please enter User Name.</span>
          </div>
          <div class="col-xl-12">
            <div class="form-group">
              <select
                name="Saluation"
                placeholder="Saluation"
                class="form-control form-control-solid form-control-lg"
                v-model="form.Saluation"
              >
                <option value="" disabled selected hidden
                  >Choose Saluation</option
                >
                <option value="Dr.">Dr.</option>
                <option value="Mr.">Mr.</option>
                <option value="Mrs.">Mrs.</option>
                <option value="Ms.">Ms.</option>
              </select>
              <span class="form-text text-muted">Please select Saluation.</span>
            </div>
          </div>
        </div>
        <!--end: Wizard Step 1-->

        <!--begin: Wizard Step 2-->
        <div class="pb-5">
          <h4 class="mb-10 font-weight-bold text-dark">
            Setup Current Location
          </h4>
          <div class="row">
            <div class="col-xl-12">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  name="address1"
                  placeholder="Address"
                  v-model="form.address"
                />
                <span class="form-text text-muted">Please enter Address.</span>
              </div>
            </div>
          </div>
          <div class="row">
            <!-- postCode -->
            <div class="col-xl-6">
              <!-- country -->
              <div class="form-group">
                <select
                  name="country"
                  placeholder="Country"
                  class="form-control form-control-solid form-control-lg"
                  v-model="form.country"
                >
                  <option value="" disabled selected hidden
                    >Choose Country</option
                  >
                  <option value="AF">Afghanistan</option>
                  <option value="AX">Åland Islands</option>
                  <option value="AL">Albania</option>
                  <option value="DZ">Algeria</option>
                  <option value="AS">American Samoa</option>
                  <option value="AD">Andorra</option>
                  <option value="AO">Angola</option>
                  <option value="AI">Anguilla</option>
                  <option value="AQ">Antarctica</option>
                  <option value="AG">Antigua and Barbuda</option>
                  <option value="AR">Argentina</option>
                  <option value="AM">Armenia</option>
                  <option value="AW">Aruba</option>
                  <option value="AU" selected>Australia</option>
                  <option value="AT">Austria</option>
                  <option value="AZ">Azerbaijan</option>
                  <option value="BS">Bahamas</option>
                  <option value="BH">Bahrain</option>
                  <option value="BD">Bangladesh</option>
                  <option value="BB">Barbados</option>
                  <option value="BY">Belarus</option>
                  <option value="BE">Belgium</option>
                  <option value="BZ">Belize</option>
                  <option value="BJ">Benin</option>
                  <option value="BM">Bermuda</option>
                  <option value="BT">Bhutan</option>
                  <option value="BO">Bolivia, Plurinational State of</option>
                  <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                  <option value="BA">Bosnia and Herzegovina</option>
                  <option value="BW">Botswana</option>
                  <option value="BV">Bouvet Island</option>
                  <option value="BR">Brazil</option>
                  <option value="IO">British Indian Ocean Territory</option>
                  <option value="BN">Brunei Darussalam</option>
                  <option value="BG">Bulgaria</option>
                  <option value="BF">Burkina Faso</option>
                  <option value="BI">Burundi</option>
                  <option value="KH">Cambodia</option>
                  <option value="CM">Cameroon</option>
                  <option value="CA">Canada</option>
                  <option value="CV">Cape Verde</option>
                  <option value="KY">Cayman Islands</option>
                  <option value="CF">Central African Republic</option>
                  <option value="TD">Chad</option>
                  <option value="CL">Chile</option>
                  <option value="CN">China</option>
                  <option value="CX">Christmas Island</option>
                  <option value="CC">Cocos (Keeling) Islands</option>
                  <option value="CO">Colombia</option>
                  <option value="KM">Comoros</option>
                  <option value="CG">Congo</option>
                  <option value="CD"
                    >Congo, the Democratic Republic of the</option
                  >
                  <option value="CK">Cook Islands</option>
                  <option value="CR">Costa Rica</option>
                  <option value="CI">Côte d'Ivoire</option>
                  <option value="HR">Croatia</option>
                  <option value="CU">Cuba</option>
                  <option value="CW">Curaçao</option>
                  <option value="CY">Cyprus</option>
                  <option value="CZ">Czech Republic</option>
                  <option value="DK">Denmark</option>
                  <option value="DJ">Djibouti</option>
                  <option value="DM">Dominica</option>
                  <option value="DO">Dominican Republic</option>
                  <option value="EC">Ecuador</option>
                  <option value="EG">Egypt</option>
                  <option value="SV">El Salvador</option>
                  <option value="GQ">Equatorial Guinea</option>
                  <option value="ER">Eritrea</option>
                  <option value="EE">Estonia</option>
                  <option value="ET">Ethiopia</option>
                  <option value="FK">Falkland Islands (Malvinas)</option>
                  <option value="FO">Faroe Islands</option>
                  <option value="FJ">Fiji</option>
                  <option value="FI">Finland</option>
                  <option value="FR">France</option>
                  <option value="GF">French Guiana</option>
                  <option value="PF">French Polynesia</option>
                  <option value="TF">French Southern Territories</option>
                  <option value="GA">Gabon</option>
                  <option value="GM">Gambia</option>
                  <option value="GE">Georgia</option>
                  <option value="DE">Germany</option>
                  <option value="GH">Ghana</option>
                  <option value="GI">Gibraltar</option>
                  <option value="GR">Greece</option>
                  <option value="GL">Greenland</option>
                  <option value="GD">Grenada</option>
                  <option value="GP">Guadeloupe</option>
                  <option value="GU">Guam</option>
                  <option value="GT">Guatemala</option>
                  <option value="GG">Guernsey</option>
                  <option value="GN">Guinea</option>
                  <option value="GW">Guinea-Bissau</option>
                  <option value="GY">Guyana</option>
                  <option value="HT">Haiti</option>
                  <option value="HM">Heard Island and McDonald Islands</option>
                  <option value="VA">Holy See (Vatican City State)</option>
                  <option value="HN">Honduras</option>
                  <option value="HK">Hong Kong</option>
                  <option value="HU">Hungary</option>
                  <option value="IS">Iceland</option>
                  <option value="IN">India</option>
                  <option value="ID">Indonesia</option>
                  <option value="IR">Iran, Islamic Republic of</option>
                  <option value="IQ">Iraq</option>
                  <option value="IE">Ireland</option>
                  <option value="IM">Isle of Man</option>
                  <option value="IL">Israel</option>
                  <option value="IT">Italy</option>
                  <option value="JM">Jamaica</option>
                  <option value="JP">Japan</option>
                  <option value="JE">Jersey</option>
                  <option value="JO">Jordan</option>
                  <option value="KZ">Kazakhstan</option>
                  <option value="KE">Kenya</option>
                  <option value="KI">Kiribati</option>
                  <option value="KP"
                    >Korea, Democratic People's Republic of</option
                  >
                  <option value="KR">Korea, Republic of</option>
                  <option value="KW">Kuwait</option>
                  <option value="KG">Kyrgyzstan</option>
                  <option value="LA">Lao People's Democratic Republic</option>
                  <option value="LV">Latvia</option>
                  <option value="LB">Lebanon</option>
                  <option value="LS">Lesotho</option>
                  <option value="LR">Liberia</option>
                  <option value="LY">Libya</option>
                  <option value="LI">Liechtenstein</option>
                  <option value="LT">Lithuania</option>
                  <option value="LU">Luxembourg</option>
                  <option value="MO">Macao</option>
                  <option value="MK"
                    >Macedonia, the former Yugoslav Republic of</option
                  >
                  <option value="MG">Madagascar</option>
                  <option value="MW">Malawi</option>
                  <option value="MY">Malaysia</option>
                  <option value="MV">Maldives</option>
                  <option value="ML">Mali</option>
                  <option value="MT">Malta</option>
                  <option value="MH">Marshall Islands</option>
                  <option value="MQ">Martinique</option>
                  <option value="MR">Mauritania</option>
                  <option value="MU">Mauritius</option>
                  <option value="YT">Mayotte</option>
                  <option value="MX">Mexico</option>
                  <option value="FM">Micronesia, Federated States of</option>
                  <option value="MD">Moldova, Republic of</option>
                  <option value="MC">Monaco</option>
                  <option value="MN">Mongolia</option>
                  <option value="ME">Montenegro</option>
                  <option value="MS">Montserrat</option>
                  <option value="MA">Morocco</option>
                  <option value="MZ">Mozambique</option>
                  <option value="MM">Myanmar</option>
                  <option value="NA">Namibia</option>
                  <option value="NR">Nauru</option>
                  <option value="NP">Nepal</option>
                  <option value="NL">Netherlands</option>
                  <option value="NC">New Caledonia</option>
                  <option value="NZ">New Zealand</option>
                  <option value="NI">Nicaragua</option>
                  <option value="NE">Niger</option>
                  <option value="NG">Nigeria</option>
                  <option value="NU">Niue</option>
                  <option value="NF">Norfolk Island</option>
                  <option value="MP">Northern Mariana Islands</option>
                  <option value="NO">Norway</option>
                  <option value="OM">Oman</option>
                  <option value="PK">Pakistan</option>
                  <option value="PW">Palau</option>
                  <option value="PS">Palestinian Territory, Occupied</option>
                  <option value="PA">Panama</option>
                  <option value="PG">Papua New Guinea</option>
                  <option value="PY">Paraguay</option>
                  <option value="PE">Peru</option>
                  <option value="PH">Philippines</option>
                  <option value="PN">Pitcairn</option>
                  <option value="PL">Poland</option>
                  <option value="PT">Portugal</option>
                  <option value="PR">Puerto Rico</option>
                  <option value="QA">Qatar</option>
                  <option value="RE">Réunion</option>
                  <option value="RO">Romania</option>
                  <option value="RU">Russian Federation</option>
                  <option value="RW">Rwanda</option>
                  <option value="BL">Saint Barthélemy</option>
                  <option value="SH"
                    >Saint Helena, Ascension and Tristan da Cunha</option
                  >
                  <option value="KN">Saint Kitts and Nevis</option>
                  <option value="LC">Saint Lucia</option>
                  <option value="MF">Saint Martin (French part)</option>
                  <option value="PM">Saint Pierre and Miquelon</option>
                  <option value="VC">Saint Vincent and the Grenadines</option>
                  <option value="WS">Samoa</option>
                  <option value="SM">San Marino</option>
                  <option value="ST">Sao Tome and Principe</option>
                  <option value="SA">Saudi Arabia</option>
                  <option value="SN">Senegal</option>
                  <option value="RS">Serbia</option>
                  <option value="SC">Seychelles</option>
                  <option value="SL">Sierra Leone</option>
                  <option value="SG">Singapore</option>
                  <option value="SX">Sint Maarten (Dutch part)</option>
                  <option value="SK">Slovakia</option>
                  <option value="SI">Slovenia</option>
                  <option value="SB">Solomon Islands</option>
                  <option value="SO">Somalia</option>
                  <option value="ZA">South Africa</option>
                  <option value="GS"
                    >South Georgia and the South Sandwich Islands</option
                  >
                  <option value="SS">South Sudan</option>
                  <option value="ES">Spain</option>
                  <option value="LK">Sri Lanka</option>
                  <option value="SD">Sudan</option>
                  <option value="SR">Suriname</option>
                  <option value="SJ">Svalbard and Jan Mayen</option>
                  <option value="SZ">Swaziland</option>
                  <option value="SE">Sweden</option>
                  <option value="CH">Switzerland</option>
                  <option value="SY">Syrian Arab Republic</option>
                  <option value="TW">Taiwan, Province of China</option>
                  <option value="TJ">Tajikistan</option>
                  <option value="TZ">Tanzania, United Republic of</option>
                  <option value="TH">Thailand</option>
                  <option value="TL">Timor-Leste</option>
                  <option value="TG">Togo</option>
                  <option value="TK">Tokelau</option>
                  <option value="TO">Tonga</option>
                  <option value="TT">Trinidad and Tobago</option>
                  <option value="TN">Tunisia</option>
                  <option value="TR">Turkey</option>
                  <option value="TM">Turkmenistan</option>
                  <option value="TC">Turks and Caicos Islands</option>
                  <option value="TV">Tuvalu</option>
                  <option value="UG">Uganda</option>
                  <option value="UA">Ukraine</option>
                  <option value="AE">United Arab Emirates</option>
                  <option value="GB">United Kingdom</option>
                  <option value="US">United States</option>
                  <option value="UM"
                    >United States Minor Outlying Islands</option
                  >
                  <option value="UY">Uruguay</option>
                  <option value="UZ">Uzbekistan</option>
                  <option value="VU">Vanuatu</option>
                  <option value="VE">Venezuela, Bolivarian Republic of</option>
                  <option value="VN">Viet Nam</option>
                  <option value="VG">Virgin Islands, British</option>
                  <option value="VI">Virgin Islands, U.S.</option>
                  <option value="WF">Wallis and Futuna</option>
                  <option value="EH">Western Sahara</option>
                  <option value="YE">Yemen</option>
                  <option value="ZM">Zambia</option>
                  <option value="ZW">Zimbabwe</option>
                </select>
                <span class="form-text text-muted">Please Choose Country.</span>
              </div>
            </div>
            <div class="col-xl-6">
              <!-- state -->
              <div class="form-group">
                <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  name="state"
                  placeholder="State"
                  v-model="form.state"
                />
                <span class="form-text text-muted">Please enter State.</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6">
              <!-- city -->
              <div class="form-group">
                <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  name="city"
                  placeholder="City"
                  v-model="form.city"
                />
                <span class="form-text text-muted">Please enter City.</span>
              </div>
            </div>

            <div class="col-xl-6">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  name="zipcode"
                  placeholder="Zip Code"
                  v-model="form.zipCode"
                />
                <span class="form-text text-muted">Please enter Zip Code.</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6">
              <!-- city -->
              <div class="form-group">
                <select
                  name="Territory"
                  placeholder="Territory"
                  class="form-control form-control-solid form-control-lg"
                  v-model="form.Territory"
                >
                  <option value="" disabled selected hidden
                    >Choose Territory</option
                  >
                  <option value="Both.">Both.</option>
                  <option value="Specfic.">Specific.</option>
                  <option value="General.">General.</option>
                </select>
                <span class="form-text text-muted"
                  >Please enter Territory.</span
                >
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Step 2-->

        <!--begin: Wizard Step 3-->
        <div class="pb-5">
          <h4 class="mb-10 font-weight-bold text-dark">
            Setup Personal Information
          </h4>

          <div class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  name="locssn"
                  placeholder="SSN"
                  v-model="form.ssn"
                />
                <span class="form-text text-muted">Please enter SSN.</span>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <select
                  name="locgender"
                  class="form-control form-control-solid form-control-lg"
                  v-model="form.gender"
                >
                  <option value="" selected>Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
                <span class="form-text text-muted">Please select gender.</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  name="locssn"
                  placeholder="Martial Status"
                  v-model="form.martialstatus"
                />
                <span class="form-text text-muted"
                  >Please enter Martial Status.</span
                >
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <select
                  name="loctype"
                  class="form-control form-control-solid form-control-lg"
                  v-model="form.skilltype"
                >
                  <option value="" selected>Select Skill Type</option>
                  <option value="">TODO</option>
                </select>
                <span class="form-text text-muted"
                  >Please select skilltype.</span
                >
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Step 3-->

        <!--begin: Wizard Step 5-->
        <div class="pb-5">
          <h4 class="mb-10 font-weight-bold text-dark">
            Enter Contact Detail
          </h4>

          <div class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  name="locstate"
                  placeholder="Email 1"
                  v-model="form.email1"
                />
                <span class="form-text text-muted">Please Enter Email 1.</span>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  name="locstate"
                  placeholder="Email 2"
                  v-model="form.email2"
                />
                <span class="form-text text-muted">Please Enter Email 2 .</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <v-checkbox
                  v-model="form.checkbox"
                  label="Is Primary"
                ></v-checkbox>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <select
                  name="loctype"
                  class="form-control form-control-solid form-control-lg"
                  v-model="form.PhoneType"
                >
                  <option value="" disabled selected hidden
                    >Please Choose Phone Type</option
                  >
                  <option value="Fax">Fax</option>
                  <option value="Home">Home</option>
                  <option value="Mobile">Mobile</option>
                  <option value="Work">Work</option>
                </select>
                <span class="form-text text-muted"
                  >Please select Phone Type.</span
                >
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  name="locstate"
                  placeholder="Number"
                  v-model="form.number"
                />
                <span class="form-text text-muted"
                  >Please Enter Phone number.</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="pb-5">
          <h4 class="mb-10 font-weight-bold text-dark">
            Enter DB Details
          </h4>
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group mt-5">
                <strong>EDUCATIONAL BACKGROUND</strong>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <span class="green-btn mb-5">
                  <a class="btn btn-success" @click="education">
                    <i class="material-icons">add</i>
                    <span>Educational Background</span></a
                  >
                </span>
              </div>
            </div>
          </div>
          <div class="row" v-if="this.educationlist.length">
            <datatable
              :header="educationheader"
              :item="educationlist"
              :showAction="showAction"
              :showSearch="showSearch"
            ></datatable>
          </div>

          <div class="row">
            <div class="col-xl-6">
              <div class="form-group mt-5">
                <strong>Employment History</strong>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <span class="green-btn mb-5">
                  <a class="btn btn-success" @click="employment">
                    <i class="material-icons">add</i>
                    <span>Employment History</span></a
                  >
                </span>
              </div>
            </div>
          </div>
          <div
            class="row mb-5"
            style="width:500px;overflow-x: scroll;"
            v-if="this.employmentlist.length"
          >
            <datatable
              :header="employmentheader"
              :item="employmentlist"
              :showAction="showAction"
              :showSearch="showSearch"
            ></datatable>
          </div>
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group mt-5">
                <strong>References</strong>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <span class="green-btn mb-5">
                  <a class="btn btn-success" @click="reference">
                    <i class="material-icons">add</i>
                    <span>References</span></a
                  >
                </span>
              </div>
            </div>
          </div>
          <div class="row mb-5" v-if="this.referencelist.length">
            <datatable
              :header="referenceheader"
              :item="referencelist"
              :showAction="showAction"
              :showSearch="showSearch"
            ></datatable>
          </div>

          <div class="form-group mt-5">
            <textarea
              placeholder="Summary Notes"
              class="form-control form-control-solid form-control-lg"
              v-model="form.snotes"
              locale="en"
            ></textarea>
            <span class="form-text text-muted"
              >Please enter Summary Notes.</span
            >
          </div>
        </div>

        <div class="pb-5">
          <h4 class="mb-10 font-weight-bold text-dark">
            Miscellaneous Questions
          </h4>

          <div class="form-group">
            <textarea
              placeholder=" Q.) Please describe your best experience as a caregiver."
              class="form-control form-control-solid form-control-lg"
              v-model="form.bestexperince"
              rows="10"
              locale="en"
            ></textarea>
          </div>
          <div class="form-group">
            <textarea
              placeholder=" Q.) Why do you want to work for our agency specifically?"
              class="form-control form-control-solid form-control-lg"
              v-model="form.specifically"
              rows="10"
              locale="en"
            ></textarea>
          </div>
          <div class="form-group">
            <textarea
              placeholder="Q.) Please describe your worst experience as a caregiver and how you handled it."
              class="form-control form-control-solid form-control-lg"
              v-model="form.handledexperience"
              rows="10"
              locale="en"
            ></textarea>
          </div>
        </div>

        <!--begin: Wizard Step 1-->

        <!--end: Wizard Step 1-->
        <!--end: Wizard Step 5-->
        <!--begin: Wizard Actions -->
        <div class="d-flex justify-content-between border-top pt-10">
          <div class="mr-2">
            <button
              class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
              data-wizard-type="action-prev"
            >
              Previous
            </button>
          </div>
          <div>
            <button
              v-on:click="submit"
              class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
              data-wizard-type="action-submit"
            >
              Submit
            </button>
            <button
              class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
              data-wizard-type="action-next"
            >
              Next Step
            </button>
          </div>
        </div>
        <!--end: Wizard Actions -->
      </form>
      <!--end: Wizard-->
    </div>
    <b-modal size="lg" id="education" centered no-close-on-backdrop>
      <template slot="modal-title">New Educational BackGround</template>
      <div class="row">
        <div class="col-xl-6">
          <div class="form-group">
            <label>School/College/University</label>
            <input
              type="text"
              class="form-control form-control-solid form-control-lg"
              name="locstate"
              v-model="school"
            />
            <span class="form-text text-muted"
              >Please Enter School/College/University.</span
            >
          </div>
        </div>
        <div class="col-xl-6">
          <div class="form-group">
            <label>Qualification</label>
            <input
              type="text"
              class="form-control form-control-solid form-control-lg"
              name="locstate"
              v-model="qualification"
            />
            <span class="form-text text-muted"
              >Please Enter Qualification.</span
            >
          </div>
        </div>
      </div>
      <template #modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="lg" variant="success" @click="AddEducation()">
          Add
        </b-button>
        <b-button size="lg" variant="danger" @click="cancel()">
          Cancel
        </b-button>
        <!-- Button with custom close trigger value -->
      </template>
    </b-modal>
    <b-modal size="lg" id="reference" centered no-close-on-backdrop>
      <template slot="modal-title">New Reference</template>
      <div class="row">
        <div class="col-xl-6">
          <div class="form-group">
            <label>Name</label>
            <input
              type="text"
              class="form-control form-control-solid form-control-lg"
              name="locstate"
              v-model="rName"
            />
            <span class="form-text text-muted">Please Enter Name.</span>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="form-group">
            <label>Phone Number</label>
            <input
              type="text"
              class="form-control form-control-solid form-control-lg"
              name="phonenumber"
              v-model="rPhoneNumber"
            />
            <span class="form-text text-muted">Please Enter Phone Number.</span>
          </div>
        </div>
      </div>
      <template #modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="lg" variant="success" @click="AddReference()">
          Add
        </b-button>
        <b-button size="lg" variant="danger" @click="cancel()">
          Cancel
        </b-button>
        <!-- Button with custom close trigger value -->
      </template>
    </b-modal>
    <b-modal size="xl" id="employment" centered no-close-on-backdrop>
      <template slot="modal-title">New Employment History</template>
      <div class="row">
        <div class="col-xl-4">
          <div class="form-group">
            <label>Name</label>
            <input
              type="text"
              class="form-control form-control-solid form-control-lg"
              name="empName"
              v-model="empName"
            />
          </div>
        </div>
        <div class="col-xl-4">
          <div class="form-group">
            <label>Adress</label>
            <input
              type="text"
              class="form-control form-control-solid form-control-lg"
              name="empAddress"
              v-model="empAddress"
            />
          </div>
        </div>
        <div class="col-xl-4">
          <div class="form-group">
            <label>Country</label>
            <input
              type="text"
              class="form-control form-control-solid form-control-lg"
              name="empCountry"
              v-model="empCountry"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-4">
          <div class="form-group">
            <label>State</label>
            <input
              type="text"
              class="form-control form-control-solid form-control-lg"
              name="empState"
              v-model="empState"
            />
          </div>
        </div>
        <div class="col-xl-4">
          <div class="form-group">
            <label>City</label>
            <input
              type="text"
              class="form-control form-control-solid form-control-lg"
              name="empCity"
              v-model="empCity"
            />
          </div>
        </div>
        <div class="col-xl-4">
          <div class="form-group">
            <label>Zip</label>
            <input
              type="text"
              class="form-control form-control-solid form-control-lg"
              name="empZip"
              v-model="empZip"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-4">
          <div class="form-group">
            <label>Served from</label>
            <b-form-datepicker
              v-model="empServedfrom"
              locale="en"
            ></b-form-datepicker>
          </div>
        </div>
        <div class="col-xl-4">
          <div class="form-group">
            <label>Served To</label>
            <b-form-datepicker
              v-model="empServedto"
              locale="en"
            ></b-form-datepicker>
          </div>
        </div>
        <div class="col-xl-4">
          <div class="form-group">
            <label>Phone Number</label>
            <input
              type="text"
              class="form-control form-control-solid form-control-lg"
              name="empPhoneNumber"
              v-model="empPhoneNumber"
            />
          </div>
        </div>
      </div>
      <template #modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="lg" variant="success" @click="Addemployment()">
          Add
        </b-button>
        <b-button size="lg" variant="danger" @click="cancel()">
          Cancel
        </b-button>
        <!-- Button with custom close trigger value -->
      </template>
    </b-modal>
  </div>
</template>
 

<script> 
import Swal from "sweetalert2";
import datatable from "../../components/datatable.vue";
export default {
  //  DataTables,SMS,  calender,mail,
  components: {
    datatable,
  }, 
  data() {
    return {
      // Remove this dummy login info
      form: {
        race: "",
        Saluation: "",
        Territory: "",
        checkbox: false,
        BaseTerritory: "",
        firstName: "",
        middleName: "",
        lastName: "",
        country: "",
        state: "",
        address: "",
        postcode: "",
        city: "",
        PhoneType: "",
        CareGiverType: "",
        gender: "",
        cellnum: "",
        skilltype: "",
        role: "",
        email: "",
        DOB: "",
        notes: "",
        ssn: "",
        hire: "",
        term: "",
      },
      showAction: false,
      showSearch: false,
      school: null,
      qualification: null,
      educationlist: [],
      employmentlist: [],
      referencelist: [],
      url: null,
      empName: null,
      rName: null,
      rPhoneNumber: null,
      empAddress: null,
      empCountry: null,
      empCity: null,
      empServedfrom: null,
      empServedto: null,
      empPhoneNumber: null,
      empState: null,
      empZip: null,
      image: null,
    };
  },
  computed: {
    educationheader() {
      return [
        {
          text: "School/College/University",
          align: "start",
          sortable: true,
          value: "school",
        },
        { text: "Qualification", value: "qualification" },
      ];
    },
    referenceheader() {
      return [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name",
        },
        { text: "Phone Number", value: "phonenumber" },
      ];
    },
    employmentheader() {
      return [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name",
        },
        { text: "Address", value: "address" },
        { text: "Country", value: "country" },
        { text: "City", value: "city" },
        { text: "State", value: "state" },
        { text: "zip", value: "Zip" },
        { text: "Phone Number", value: "phonenumber" },
        { text: "Serverd From", value: "serverdFrom" },
        { text: "Served To", value: "servedTo" },
      ];
    },
  }, 
  
  methods: {
    submit: function(e) {
      e.preventDefault();
      Swal.fire({
        title: "",
        text: "The application has been successfully submitted!",
        icon: "success",
        confirmButtonClass: "btn btn-secondary",
      });
    },
    Preview_image() {
      this.url = URL.createObjectURL(this.image);
    },
    education() {
      this.$bvModal.show("education");
    },
    employment() {
      this.$bvModal.show("employment");
    },
    reference() {
      this.$bvModal.show("reference");
    },
    AddEducation() { 
      this.educationlist.push({
        school: this.school,
        qualification: this.qualification,
      });
      this.school = "";
      this.qualification = "";
      this.$bvModal.hide("education");
    },
    AddReference() {
      this.referencelist.push({
        name: this.rName,
        phonenumber: this.rPhoneNumber,
      });
      this.rName = "";
      this.rPhoneNumber = "";
      this.$bvModal.hide("reference");
    },
    Addemployment() { 
      this.employmentlist.push({
        name: this.empName,
        country: this.empCountry,
        city: this.empCity,
        state: this.empState,
        zip: this.empZip,
        address: this.empAddress,
        phonenumber: this.empPhoneNumber,
        serveFrom: this.empServedfrom,
        serveTo: this.empServedto,
      });
      (this.empName = ""),
        (this.empCountry = ""),
        (this.empCity = ""),
        (this.empState = ""),
        (this.empZip = ""),
        (this.empAddress = ""),
        (this.empPhoneNumber = ""),
        (this.empServedfrom = ""),
        (this.empServedto = ""),
        this.$bvModal.hide("employment");
    },
  },
};
</script> 