<template>
  <div>
    <div class="col-6" v-if="showSearch">
      <v-text-field v-model="search" label="Search"></v-text-field>
    </div>
    <div v-if="this.header">
      <v-data-table
        :headers="header"
        :items="item"
        item-key="name"
        class="elevation-1"
        :search="search"
        hide-default-header
      >
        <template v-slot:header="{ props }">
          <thead class="v-data-table-header">
            <tr>
              <th
                v-for="(head, index) in props.headers"
                :key="index"
                class="text-uppercase"
                scope="col"
                style="padding-right:100px"
              >
                {{ head.text }}
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:item="row">
          <tr>
            <td v-for="(item, index) in row.item" :key="index">{{ item }}</td>
            <td style="width:auto" v-if="showAction">
              <button @click="sendMail()" class="mr-1">
                <i class="far fa-envelope"></i>
              </button>
              <button @click="Schedule()" class="mr-1">
                <i class="far fa-calendar-check"></i>
              </button>
              
              <button v-if="showChangePass" @click="ChangePass()" class="mr-1">
                <i class="fas fa-key"></i>
              </button>

              <button @click="Sms()" class="mr-1">
                <i class="fas fa-comment-dots"></i>
              </button>
              <button @click="Edit()" class="mr-1">
                <i class="far fa-eye edit_btn"></i>
              </button>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
    <b-modal size="lg" id="sendMail" hide-footer centered no-close-on-backdrop>
      <template slot="modal-title">Send Mail</template>
      <mail></mail>
    </b-modal>
    <b-modal size="lg" id="sendSms" hide-footer centered no-close-on-backdrop>
      <template slot="modal-title">Send SMS</template>
      <SMS></SMS>
    </b-modal>
    <b-modal size="xl" id="calender" hide-footer centered no-close-on-backdrop>
      <template slot="modal-title">Send SMS</template>
      <calender></calender>
    </b-modal>
    <b-modal size="sm" id="ChangePass" centered no-close-on-backdrop>
      <template slot="modal-title">Send SMS</template>
      <div class="row">
        <div class="col-xl-6">
          <div class="form-group">
            <label>Temporary password</label>
            <input
              type="password"
              class="form-control form-control-solid form-control-lg"
              name="locstate"
              v-model="temporarypassword"
            />
            <span class="form-text text-muted"
              >Please Enter temporarry Passwrod .</span
            >
          </div>
        </div>
        <div class="col-xl-6">
          <div class="form-group">
            <label>Confirm Temporary Password *</label>
            <input
              type="password"
              class="form-control form-control-solid form-control-lg"
              name="locstate"
              v-model="confirmpassword"
            />
            <span class="form-text text-muted"
              >Please Confirm Temporary Password .</span
            >
          </div>
        </div>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="lg" variant="success" @click="ok()">
          Update
        </b-button>
        <b-button size="lg" variant="danger" @click="cancel()">
          Cancel
        </b-button>
        <!-- Button with custom close trigger value -->
      </template>
    </b-modal>
  </div>
</template>
<script>
// import createwizard from "../../components/createwizard.vue";
// import prospectiveClientWizard from "../../components/prospectiveClientWizard.vue";
import mail from "./mail.vue";
import SMS from "./sms.vue";
import calender from "./calender.vue";
export default {
  // createwizard, prospectiveClientWizard,
  components: { mail, SMS, calender },
  props: {
    header: [],
    item: [],
    showChangePass:Boolean,
    showAction:Boolean,
    showSearch:Boolean
  },
  data() {
    return {
      search: "",
      temporarypassword: "",
      confirmpassword: "",
    };
  },
  created(){ 
  },
  computed: {},
  methods: {
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    sendMail() {
      this.$bvModal.show("sendMail");
    },
    Sms() {
      this.$bvModal.show("sendSms");
    },
    Schedule() {
      this.$bvModal.show("calender");
    },
    ChangePass() {
      this.$bvModal.show("ChangePass");
    },
    Edit() {
      this.$emit("editRecord");
    },
  },
};
</script>
<style scoped>
.titlename {
  float: left !important;
  padding-left: 19px;
}
.pageheading {
  padding-top: 20px;
  padding-bottom: 50px;
  height: 50px;
  background-color: #ffffff;
}
</style>
