<template>
  <div>
    <kalendar :configuration="calendar_settings" :events="events">
      <div
        slot="created-card"
        slot-scope="{ event_information }"
        class="details-card"
        style="height:auto"
      >
        <h2 class="appointment-title">{{ event_information.data.title }}</h2    > 
        <b-button pill variant="link" style="display:block;padding-left: 0px;" @click="removeEvent(event_information)">Show </b-button>
         
      </div>
      <!-- CREATING CARD SLOT -->
      <div slot="creating-card">
        <h4 class="appointment-title" style="text-align: left;">
          New Appointment
        </h4>
      </div>
      <!-- POPUP CARD SLOT -->
      <div
        slot="popup-form"
        slot-scope="{ popup_information }"
        style="display: flex; flex-direction: column;"
      >
        <h4 style="margin-bottom: 10px">
          New Appointment
        </h4>
        <input
          v-model="new_appointment['title']"
          type="text"
          name="title"
          placeholder="Title"
        />
        <textarea
          v-model="new_appointment['description']"
          type="text"
          name="description"
          placeholder="Description"
          rows="2"
        ></textarea>
        <div class="buttons"> 
          <b-button pill variant="outline-danger" @click="closePopups()">Cancel</b-button>
            <b-button pill variant="success" @click="addEvent(popup_information)">Save</b-button>
      
        </div>
      </div></kalendar
    >
     <b-modal
      size="sl"
      id="scheduleTime"
      hide-footer
      centered
      no-close-on-backdrop
    >
      <template slot="modal-title">Info</template>
     <div> <h3>{{this.title}}</h3></div>
     <div>{{this.description}} </div>
    </b-modal>
  </div>
</template>
<script>
import { Kalendar } from "kalendar-vue";
export default {
  components: {
    Kalendar,
  },
  data: () => ({
    events: [
      {
    from: "Thur JUly 14 2021 02:00:00",
    to: "Thur JUly 14 2021 04:00:00",
    date: "2021-07-14",
    data: {
      title: "My Birthday",
      description: "Lorem ipsum dolor sit amet."
    }
    
  }
    ],
    calendar_settings: {
      style: "material_design",
      view_type: "month",
      cell_height: 20,
      scrollToNow: true,
      current_day: new Date(),
      read_only: false,
      day_starts_at: 0,
      day_ends_at: 24,
      overlap: true,
      hide_dates: ["2019-10-31"], // Spooky
      hide_days: [6],
      past_event_creation: true,
    },
    new_appointment: {
      title: null,
      description: null,
    },
    title:'',
    description:''
  }),
  methods: {
    addEvent(popup_data) { 
      let payload = {
            data: {
          title: this.new_appointment.title,
          description: this.new_appointment.description,
        },
        from: popup_data.start_time,
        to: popup_data.end_time,
      };

      this.$kalendar.addNewEvent(payload);
      this.$kalendar.closePopups();
      (this.new_appointment.title = null),
        (this.new_appointment.description = null);
    },

    // Remove Event
    removeEvent(kalendarEvent) { 
      this.title=kalendarEvent.data.title;
      this.description=kalendarEvent.data.description;
      this.$bvModal.show("scheduleTime"); 
    //   let day = kalendarEvent.start_time.slice(0, 10);
    //   this.$kalendar.removeEvent({
    //     day,
    //     key: kalendarEvent.key,
    //     id: kalendarEvent.kalendar_id,
    //   });
    },
  },
};
</script>
