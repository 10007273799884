<template>
  <div>
    <div class="page-title pageheading" style="height: 50px;">
      <h3 class="titlename">
        <i class="fas fa-user"></i
        ><span class="ml-3">Caregiver Applicant</span>
      </h3>
      <span class="float-right green-btn">
        <a class="btn btn-success mr-3" @click="editRecord">
          <i class="material-icons">add</i>
          <span>New Caregiver Applicant</span></a
        >
      </span>
    </div>
    <div>
      <datatable
        :header="headers"
        :item="desserts"
        :showChangePass="showChangePass"
        :showAction="true"
        :showSearch="true"
        @editRecord="editRecord()"
      ></datatable>
    </div>
    <b-modal
      size="xl"
      id="createuser"
      hide-footer
      centered
      no-close-on-backdrop
    >
      <template slot="modal-title">Add New Staff</template>
      <createwizard></createwizard>
    </b-modal>
  </div>
</template>
<script>
import createwizard from "./createCareGiverApplicant.vue";
import datatable from "../../components/datatable.vue";
export default {
  //  DataTables,SMS,  calender,mail,
  components: {
    createwizard,
    datatable,
  },
  data() {
    return {
      search: "",
      showChangePass: true,
      clientid: "",
      desserts: [],
    };
  },
  computed: {
    headers() {
      return [
        { text: "Name", align: "start", sortable: true, value: "name" },
        { text: "Phone Number", value: "phonenumber" },
        { text: "Status", value: "status" },
        { text: "Territory", value: "territory" },
        { text: "Application Date", value: "applicationdate" },
        { text: "Email", value: "email" },
        { text: "Action", value: "action" },
      ];
    },
  },
  methods: {
    editRecord() {
      this.$bvModal.show("createuser");
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
  },
};
</script>
<style scoped>
.titlename {
  float: left !important;
  padding-left: 19px;
}
.pageheading {
  padding-top: 20px;
  padding-bottom: 50px;
  height: 50px;
  background-color: #ffffff;
}
</style>
