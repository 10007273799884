<template>
  <div>
    <v-text-field v-model="from" label="From"></v-text-field>

    <v-text-field v-model="to" label="To"></v-text-field>
    <v-text-field v-model="cc" label="Cc"></v-text-field>

    <v-text-field v-model="bcc" label="Bcc"></v-text-field>

    <v-text-field v-model="subject" label="Subject"></v-text-field>
    <v-container fluid>
      <v-textarea
        name="input-7-1"
        maxlength = '250'
        filled
        label="Body"
        auto-grow
        v-model="body"
      ></v-textarea>
    </v-container>
    <!-- <v-file-input
        v-model="files"
        color="deep-purple accent-4"
        counter
        label="File input"
        multiple
        placeholder="Select your files"
        prepend-icon="mdi-paperclip"
        outlined
        :show-size="1000"
      >
        <template v-slot:selection="{ index, text }">
          <v-chip
            v-if="index < 2"
            color="deep-purple accent-4"
            dark
            label
            small
          >
            {{ text }}
          </v-chip>

          <span
            v-else-if="index === 2"
            class="text-overline grey--text text--darken-3 mx-2"
          >
            +{{ files.length - 2 }} File(s)
          </span>
        </template>
      </v-file-input> -->
    <v-btn class="mr-4" @click="create">
      submit
    </v-btn>
  </div>
</template>
<script>
import Swal from "sweetalert2";
export default {
  data: () => ({
    // files: [],
    from: null,
    to: null,
    cc: null,
    bcc: null,
    subject: null,
    body: null,
  }),
  methods: {
    create() {
      Swal.fire({
        title: "",
        text: "The mail has been successfully sent!",
        icon: "success",
        confirmButtonClass: "btn btn-secondary",
      });
      
    },
  },
};
</script>
